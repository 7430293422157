import React from "react"
import { Link } from "gatsby"
import posed from "react-pose"
import styles from "../styles/blog-summary.module.css"

const BlogIcon = posed.div({
  hoverable: true,
  pressable: true,
  init: {
    scale: 1,
  },
  hover: {
    scale: 1.05,
  },
  press: {
    scale: 0.95,
  },
})

class BlogSummary extends React.Component {
  render() {
    return (
      <div className={styles.summary}>
        <Link to={this.props.link}>
          <BlogIcon className={styles.picture}>
            <img
              className={styles.pictureImage}
              src={this.props.image}
              alt="Icon"
              height="100"
              width="100"
            />
          </BlogIcon>
        </Link>
        <Link to={this.props.link}>
          <div className={styles.info}>
            <h4>
              {this.props.title} | {this.props.date}
            </h4>
            <div
              className={styles.desc}
              dangerouslySetInnerHTML={{ __html: this.props.excerpt }}
            />
          </div>
        </Link>
      </div>
    )
  }
}

export default BlogSummary
